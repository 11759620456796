// Distribute to components using global mixin
import Vue from 'vue'

const eventHub = new Vue()

const innerSizeWindow = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () => {
  innerSizeWindow.width = window.innerWidth
  innerSizeWindow.height = window.innerHeight
}, true)

Vue.mixin({
  data: () => ({
    innerSizeWindow: innerSizeWindow,
    eventHub: eventHub
  })
})
