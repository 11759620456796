import Header from './components/template/Header/component.vue'
import Footer from './components/template/Footer/component.vue'

export const ComponentsCollection = {
  /* app components */
  Header: () => Promise.resolve(Header),
  Footer: () => Promise.resolve(Footer),

  BannerMain: () => import('./components/common/BannerMain/component.vue' /* webpackChunkName: "BannerMain" */),
  MainReviews: () => import('./components/common/MainReviews/component.vue' /* webpackChunkName: "MainReviews" */),
  MainPosts: () => import('./components/common/MainPosts/component.vue' /* webpackChunkName: "MainPosts" */),
  MainTags: () => import('./components/common/MainTags/component.vue' /* webpackChunkName: "MainTags" */),
  Posts: () => import('./components/common/Posts/component.vue' /* webpackChunkName: "Posts" */),
  Contacts: () => import('./components/common/Contacts/component.vue' /* webpackChunkName: "Contacts" */),
  PostDetail: () => import('./components/common/PostDetail/component.vue' /* webpackChunkName: "PostDetail" */),
  Documents: () => import('./components/common/Documents/component.vue' /* webpackChunkName: "Documents" */),
  Page404: () => import('./components/common/Page404/component.vue' /* webpackChunkName: "Page404" */),
  ContactsDetail: () => import('./components/common/ContactsDetail/component.vue' /* webpackChunkName: "ContactsDetail" */),
  MainSteps: () => import('./components/common/MainSteps/component.vue' /* webpackChunkName: "MainSteps" */)
}
