var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("div", {
                  staticClass: "mb-5",
                  domProps: { innerHTML: _vm._s(_vm.data.text1) }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "mb-8",
                  domProps: { innerHTML: _vm._s(_vm.data.text2) }
                }),
                _vm._v(" "),
                _c("div", { domProps: { innerHTML: _vm._s(_vm.data.text3) } })
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "auto" } },
                [_c("div", { domProps: { innerHTML: _vm._s(_vm.data.text4) } })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "developer-link text-left text-md-right" }, [
            _vm._v(
              "\n\t\t\tДизайн: Татьяна Хилютко, Андрей Дуркин, Дина Леонова, Диана Романова. "
            ),
            _c("br"),
            _vm._v(" "),
            _c(
              "a",
              { attrs: { target: "_blank", href: "https://www.newsite.by/" } },
              [_vm._v("Разработка сайта ")]
            ),
            _vm._v(" — Новый Сайт\n\t\t")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }