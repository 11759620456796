import BaseComponent from '../../../BaseComponent'
import Vuetify from 'vuetify'

export default {
  name: 'Header',
  extends: BaseComponent,
  vuetify: new Vuetify(),
  data: () => ({
    drawer: false,
    data: null,
    isMain: window.location.pathname === '/'
  })
}
