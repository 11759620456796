import Vue from 'vue'
import './vue/GlobaMixinVue'
import VueResource from 'vue-resource'
import { ComponentsCollection } from './vue/ComponentsCollection'
import { vueInvoker } from './vue/VueInvoker'
import { main } from './scripts/Main'
import Vuetify from 'vuetify'

Vue.use(Vuetify, {
  dark: true,
  theme: { disable: true }
})

Vue.use(VueResource)

main.initModules()

vueInvoker.prepareComponents(ComponentsCollection).then(() => {
  console.log('COMPONENTS READY')
  if (document.querySelector('._loading--page') !== null) {
    setTimeout(() => {
      document.querySelector('._loading--page').remove()
    }, 333)
  }
})
