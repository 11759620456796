import BaseComponent from '../../../BaseComponent'
import Vuetify from 'vuetify'

export default {
  name: 'Footer',
  extends: BaseComponent,
  vuetify: new Vuetify(),
  data: () => ({
    data: null
  })
}
